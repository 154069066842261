.mini-article {
  height: 180px;
}

@media only screen and (max-width: 768px) {
}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 1008px) {

}