/* modal */
.ReactModal__Overlay {
  transition: opacity 170ms ease-in-out;
  opacity: 0;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  transition: all 170ms ease-in-out;
  transform: translateY(25px);
  opacity: 0;
}
.ReactModal__Content--after-open {
  opacity: 1;
  transform: translateY(0);
}
.ReactModal__Content--before-close {
  opacity: 0;
  transform: translateY(25px);
}

/* scroll bars */
.surface-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}
.surface-scroll::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
}
.surface-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(47 47 40);
  border-radius: 9999px;
  border: 4px solid rgb(27 27 19);
}

/* spinner */
.spinner {
  animation: rotate 1s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}
.spinner .path {
  stroke: theme(colors.edge.DEFAULT);
  opacity: 0.75;
  -webkit-animation: dash 1s ease-in-out infinite;
  animation: dash 1s ease-in-out infinite;
  width: 50px;
  height: 50px;
}

.rumble-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 80.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-rumble-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.visible-xs {
  display: none;
}

@media screen and (max-width: 576px){ 
  .hidden-xs {
    display: none;
  }
  .visible-xs {
    display: block;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
