.portrait-article {
  min-width: 164px;
  height: 284px;
}

.article-headline {
  white-space: normal;
  column-width: 400px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 400px) {
  .portrait-article {
    .action-bar * {
        border: none !important;
        border-left: none !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .portrait-article {
    .article-card-header {
      height: 142px;
      min-height: 142px;
      max-height: 142px !important;
    }
  }
}

@media only screen and (min-width: 768px) {
  .portrait-article {
    .article-card-header {
      height: 142px;
      min-height: 142px;
      max-height: 142px !important;
    }
  }
}

@media only screen and (min-width: 1008px) {
  .portrait-article {
    .article-card-header {
      height: 142px;
      min-height: 142px;
      max-height: 142px !important;
    }
  }
}