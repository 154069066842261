.spin-in-cw {
  animation: spin-in-cw 0.175s ease-out both;
}

@keyframes spin-in-cw {
  0% {
    transform: rotate(-440deg) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}
