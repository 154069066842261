.sl {
  padding: 0px 24px;
}

.sl .card {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0px 15px 33px 0px rgba(0, 0, 0, 0.08);
  padding: 24px;
  width: 396px;
  text-align: left;
  box-sizing: content-box;
}

.sl .card-title {
  font-weight: bolder;
  font-size: 32px;
  font-family: Chivo,sans-serif;
  color: #1E222A;
  margin-bottom: 24px;
}

@media (width <= 500px) {
  .sl .card {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 24px;
    background-color: inherit;
    box-shadow: none;
    padding: 0px;
    width: initial;
    text-align: left;
    box-sizing: content-box;
  }
}