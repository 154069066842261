.admin-rui-block:hover {
  outline: 3px dashed rgba(0, 0, 0, 0.37);
  outline-offset: 4px;
  position: relative;
}

.admin-rui-block .panel {
  position: absolute;
  height: 28px;
  min-width: 24px;
  top: -2px;
  right: -2px;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity .1s ease-out;
  display: flex;
  gap: 4px
}

.admin-rui-block:hover .panel {
  opacity: 1;
}

.admin-rui-block .panel .item {
  min-width: 28px;
  height: 28px;
  border-radius: 24px;
  background-color: #00000066;
  box-shadow: 1px 1px 2px #00000054;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffffc4;
  transition: transform .1s ease-out;
  font-size: 10px;
  font-weight: bold;
  user-select: none;
  display: flex;
  gap: 4px;
}

.admin-rui-block .panel .item.enabled {
  color: black;
  background-color: rgba(255,255,255,.9);
}


.admin-rui-block .panel .item:hover {
  transform: scale(1.2);
  cursor: pointer;
}