.article-headline {
  overflow: hidden;
}

.article-32 .article-headline {
  font-size: 32px;
  line-height: 32px;
  min-height: 64px;
}

.article-28 .article-headline {
  font-size: 28px;
  line-height: 32px;
}

.article-20 .article-headline {
  font-size: 20px;
  line-height: 20px;
}

.article-16 .article-headline {
  font-size: 16px;
  line-height: 19px;
}

.article-14 .article-headline {
  font-size: 14px;
  line-height: 14px;
}