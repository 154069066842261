.size-x-small {
  max-width: 10px;
}

.size-small {
  max-width: 14px;
}

.size-medium {
  max-width: 20px;
}

.size-large {
  max-width: 30px;
}

.size-x-large {
  max-width: 46px;
}
