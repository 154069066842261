.message {
  font-size: 90%;
}

.type {
  font-size: 80%;
}

.type-info {
  composes: type;
}

.type-success {
  composes: type;
}

.type-warning {
  composes: type;
}

.type-error {
  composes: type;
}
